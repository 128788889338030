<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex flex-column mt-4">
      <v-text-field
        class="field"
        :label="$t('t.IdentifiantAPI')"
        v-model="computedLoginApi"
        :rules="ruleRequiredMax200"
        :clearable="true"
      />
      <v-text-field
        class="field"
        :label="$t('t.PasswordAPI')"
        v-model="computedPasswordApi"
        :type="'password'"
        :rules="ruleRequiredMax200"
        :clearable="true"
      />
      <date-picker-menu
        v-model="computedLastCall"
        :label="$t('t.LastCall')"
      />
      <column-picker
        :attach="false"
        :clearable="true"
        :label="$t('t.Balance')"
        :filters="{ 'document-types': [['accounts'],[]]}"
        :selected-col-id.sync="computedColBalanceId"
        currency-disabled
        consolidation-disabled
        dense
        :rules="required"
        amt-cols-only
        no-cols-relation
        no-cols-aging
      />
    </div>
  </div>
</template>

<script>

export default {
  components: {
    ColumnPicker: () => import('@/components/column-picker'),
    DatePickerMenu: () => import('@/components/date-picker-menu')
  },
  data () {
    return {
      required: [v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataLoginApi: null,
      dataPasswordApi: null,
      dataLastCall: null,
      dataColBalanceId: null,
      passwordChanged: false
    }
  },
  computed: {
    ruleRequiredMax200 () {
      return [
        v => !!v || this.$t('t.IsRequired'),
        v => (v ?? '').length <= 200
      ]
    },
    computedLoginApi: {
      get () {
        return this.dataLoginApi
      },
      set (v) {
        this.dataLoginApi = v
        this.dataDocumentEmit()
      }
    },
    computedColBalanceId: {
      get () {
        return this.dataColBalanceId
      },
      set (v) {
        this.dataColBalanceId = v
        this.dataDocumentEmit()
      }
    },
    computedPasswordApi: {
      get () {
        return this.dataPasswordApi
      },
      set (v) {
        this.passwordChanged = this.dataPasswordApi !== v
        this.dataPasswordApi = v
        this.dataDocumentEmit()
      }
    },
    computedLastCall: {
      get () {
        return this.dataLastCall
      },
      set (v) {
        this.dataLastCall = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        ty: 'groupama',
        v: {
          loginApi: this.dataLoginApi,
          passwordApi: { password: this.dataPasswordApi, passwordChanged: this.passwordChanged, providerId: this.dataProviderId },
          lastCall: this.dataLastCall,
          colBalanceId: this.dataColBalanceId,
          encryptKey: this.dataEncryptKey
        }
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    isDirty: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    document: {
      immediate: true,
      handler (d) {
        const v = d?.v
        this.dataProviderId = v?.passwordApi?.providerId
        this.dataEncryptKey = v?.encryptKey
        this.dataLoginApi = v?.loginApi
        this.dataPasswordApi = v?.passwordApi?.password
        this.dataColBalanceId = v?.colBalanceId
        this.dataLastCall = v?.lastCall
      }
    },
    isDirty: {
      immediate: true,
      handler (v) {
        if (!v) {
          this.passwordChanged = false
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.field
  max-width 30%

.connect-btn
  max-width 300px
</style>
